<template>
	<div class="tooltip-group" v-if="visible" v-bind="$attrs" v-on="$listeners">
		<div class="tooltip-arrow" :class="arrowDirection" />
		<div class="tooltip-content">
			<!-- <div class="tooltip-icon" v-if="icon">
        <Icon :name="icon" size="md" />
      </div> -->
			<div class="tooltip-text-content">
				<div class="tooltip-title" v-html="title" />
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Tooltip",
		props: {
			/**
			 * Se tooltip está visível<br>
			 * Value: `true` `false`
			 **/
			visible: {
				type: Boolean,
			},
			/**
			 * Direção da flecha<br>
			 * Value: `left` `right` `top-start` `top-center` `top-end` `bottom-start` `bottom-center` `bottom-end`
			 **/
			// arrowDirection: {
			//   type: String,
			//   default: 'bottom-start'
			// },
			// /**
			// * Ícone a ser exibido<br>
			// * Example: `calendar`
			// **/
			// icon: {
			//   type: String
			// },
			/**
			 * Título do tooltip, pode ser vazio<br>
			 * Example: `Tooltip title`
			 **/
			title: {
				type: String,
			},
			/**
			 * Texto do tooltip<br>
			 * Example: `Tooltip paragraph dummy text of the printing and typesetting industry. Lorem Ipsum has been the dentro da história.`
			 **/
			// text: {
			//   type: String
			// }
		},
		data() {
			return {};
		},
	};
</script>

<style lang="scss" scoped>
	// @import "./node_modules/@tiddh/brave-tokens/dist/styles/style.scss";

	// .tooltip-group {
	//   transition: all .3s;
	//   display: flex;
	//   flex-direction: row;
	//   position: relative;

	//   //SHAPE
	//   padding: px-size('lg', $spacing-inset-xs);
	//   border-radius: $border-radius-md;
	//   background: $color-highlight-quaternary;
	//   max-width: px-size('lg', 325px);
	//   @include media-breakpoint-down('sm') {
	//     padding: px-size('sm', $spacing-inset-xxs);
	//     max-width: px-size('sm', 240px);
	//   }

	//   .tooltip-arrow {
	//     position: absolute;
	//     width: 0;
	//     height: 0;
	//     border: px-size('lg', 16px) solid $color-highlight-quaternary;
	//     border-top: px-size('lg', 16px) solid;
	//     border-right: px-size('lg', 16px) solid;
	//     color: $color-highlight-quaternary;

	//     &.left {
	//       left: px-size('lg', -1*$spacing-inline-xs);
	//       top: 0;
	//       bottom: 0;
	//       margin: auto 0;
	//       transform: rotate(135deg);
	//     }
	//     &.right {
	//       right: px-size('lg', -1*$spacing-inline-xs);
	//       top: 0;
	//       bottom: 0;
	//       margin: auto 0;
	//       transform: rotate(135deg);
	//     }

	//     &.top-start {
	//       top: px-size('lg', -1*$spacing-stack-xs);
	//       left: px-size('lg', $spacing-inline-md);
	//       transform: rotate(135deg);
	//     }
	//     &.top-center {
	//       top: px-size('lg', -1*$spacing-stack-xs);
	//       left: 50%;
	//       transform: rotate(135deg) translate(50%, 50%);
	//     }
	//     &.top-end {
	//       top: px-size('lg', -1*$spacing-stack-xs);
	//       right: px-size('lg', $spacing-inline-md);
	//       transform: rotate(135deg);
	//     }

	//     &.bottom-start {
	//       bottom: px-size('lg', -1*$spacing-stack-xs);
	//       left: px-size('lg', $spacing-inline-md);
	//       transform: rotate(135deg);
	//     }
	//     &.bottom-center {
	//       bottom: px-size('lg', -1*$spacing-stack-xs);
	//       left: 50%;
	//       transform: rotate(135deg) translate(50%, 50%);
	//     }
	//     &.bottom-end {
	//       bottom: px-size('lg', -1*$spacing-stack-xs);
	//       right: px-size('lg', $spacing-inline-md);
	//       transform: rotate(135deg);
	//     }
	//   }

	//   .tooltip-content {
	//     display: flex;
	//     position: relative;
	//     z-index: 20;
	//     .tooltip-icon {
	//       align-items: center;
	//       justify-content: center;
	//       display: flex;

	//       margin-right: px-size('lg', $spacing-inline-sm);
	//       @include media-breakpoint-down('sm') {
	//         margin-right: px-size('sm', $spacing-inline-xs);
	//       }
	//     }

	//     .tooltip-text-content {
	//       display: flex;
	//       flex-direction: column;

	//       //TITLE
	//       .tooltip-title {
	//         font-family: $font-family-comp-01;
	//         color: $color-neutral-lightest;
	//         font-weight: $font-weight-bold;
	//         line-height:$line-height-md;
	//         font-size: px-size('lg', $font-size-xxs);
	//         text-align: left;
	//         @include media-breakpoint-down('sm') {
	//           font-size: px-size('xxs', $font-size-xxxs);
	//         }
	//       }

	//       //TEXT
	//       .tooltip-text {
	//         font-family: $font-family-comp-01;
	//         color: $color-neutral-lightest;
	//         line-height: $line-height-md;
	//         font-size: px-size('lg', $font-size-xxs);
	//         text-align: left;
	//         @include media-breakpoint-down('sm') {
	//           font-size: px-size('xxs', $font-size-xxxs);
	//         }
	//       }
	//     }
	//   }
	// }
</style>
